import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Card from "components/shared/Card";
import { StaticImage } from "gatsby-plugin-image";

export default () => (
  <div className={classes.container}>
    <Layout>
      <h3>What We Can Do For You</h3>
      <div className={classes.cards}>
        <Card
          title="Localization"
          href="/services/localization/"
          content={
            <p>
              Comply with local laws, protect your intellectual property and
              translate your app or game in China
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/localization-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="constrained"
            />
          }
        />
        <Card
          title="Monetization"
          href="/services/monetization/"
          content={
            <p>
              Your current in-app payments will not work in China. Meet{" "}
              AppInChina Pay: the easy way to accept WeChat Pay & Alipay.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/monetize-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="constrained"
            />
          }
        />
        <Card
          title="Distribution"
          href="/services/distribution/"
          align="vertical"
          content={
            <p>
              We will walk you through the requirements to publish your app or
              game to China's top 15 Android stores and the Apple App Store
              China (iOS).
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/distribution-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="constrained"
            />
          }
        />
        <Card
          title="Game Publishing"
          href="/services/game-publishing/"
          align="vertical"
          content={
            <p>
              Half of China plays mobile games. We will get them playing yours
              by taking care of every step - from distribution to promotion to
              monetization.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/games-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
        <Card
          title="User Acquisition"
          href="/services/acquisition/"
          content={
            <p>
              Learn which user acquisition (UA) methods work best in China, view
              analytics, and manage your ASO and CPD campaigns.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/acquisition-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
        <Card
          title="Integration"
          href="/services/integration/"
          content={
            <p>
              Your current SDKs & cloud services may not work in China. Test
              your app or game and learn which SDKs will.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/integration-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
        <Card
          title="Hosting"
          href="/services/hosting/"
          align="vertical"
          content={
            <p>
              Your app or game will run better when hosted locally in China. Our
              hosting service will ensure you comply with Chinese data laws.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/hosting-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
        <Card
          title="Compliance"
          href="/services/compliance/"
          align="vertical"
          content={
            <p>
              We will bring your company, product, and service into full
              compliance with Chinese law.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/compliance-aic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
        <Card
          title="Other Services"
          href="/services/other/"
          align="vertical"
          content={
            <p>
              We offer solutions for eCommerce, Software as a Service (SaaS),
              WeChat Mini Programs, and more.
            </p>
          }
          staticImg={
            <StaticImage
              src="../../../images/color_graphics/otheraic.jpg"
              alt="Localization"
              placeholder="blurred"
              layout="fullWidth"
            />
          }
        />
      </div>
    </Layout>
  </div>
);
