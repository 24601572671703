import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";
import Button from "components/shared/Button";
import right from "images/svg_icons/right.svg";
import wall from "images/color_graphics/wall.svg";
// import new_hero from "images/color_graphics/new_hero_4.png";
import { Link } from "gatsby";

let setLocalStorage = (key, defaultValue) => {if (typeof window !== "undefined") {
  window.localStorage.setItem(key, JSON.stringify(defaultValue));
}};

export default () => (
  <div className={classes.container}>
    <Layout>
      <div className={classes.left}>
        <h1>Ready to Conquer the Chinese Market?</h1>
        <h2>
          The most trusted way to publish your <b>app</b>, <b>game</b> or{" "}
          <b>platform</b> in China
        </h2>
        <Link to="/get-started/">
          <Button 
            onClick={() =>{
              setLocalStorage("ctaId", "homePageHeroCta")
            }}
            icon={right}
          >Get Started Today</Button>
        </Link>
      </div>
      <div className={classes.right}>
        <img src={wall} alt="China" />
      </div>
    </Layout>
  </div>
);
