// extracted by mini-css-extract-plugin
export var carousel = "index-module--carousel--42EHJ";
export var container = "index-module--container--Lz2Fn";
export var content = "index-module--content--RvOQw";
export var description = "index-module--description--1hl9a";
export var flex = "index-module--flex--QXbIu";
export var item = "index-module--item--id5pt";
export var list = "index-module--list--s1r5a";
export var name = "index-module--name--gXCbM";
export var position = "index-module--position--dRHTh";
export var subflex = "index-module--subflex--LwUQ-";