import React from "react";
import Meta from "components/shared/Meta";
import Nav from "components/shared/Nav";
import Footer from "components/shared/Footer";
import Intro from "components/index/Intro2";
import Quotes from "components/index/Quotes";
import CompanyLogos from "components/index/CompanyLogos";
import MediaLogos from "components/index/MediaLogos";
import Services from "components/index/Services";
import CTA from "components/shared/CTA";
import Async from "components/shared/Async";

export default () => (
  <>
    <Meta url="/" />
    <Nav />
    <Intro />
    <Quotes />
    <CompanyLogos />
    <MediaLogos />
    <Services />
    <Async label="index cta">
      <CTA />
    </Async>
    <Footer />
  </>
);
