import React from "react";
import * as classes from "./index.module.less";
import Layout from "components/shared/Layout";

import { CarouselProvider, Slider, Slide, DotGroup } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

export default ({ items, interval, render }) => (
  <div className={classes.container}>
    <Layout>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={35}
        isIntrinsicHeight={true}
        interval={interval || 8000}
        totalSlides={items.length}
        isPlaying={true}
        infinite={true}
        playDirection="forward"
      >
        <Slider>
          {items.map((item, index) => (
            <Slide index={index} key={index}>
              {render(item)}
            </Slide>
          ))}
        </Slider>
        <DotGroup />
      </CarouselProvider>
    </Layout>
  </div>
);
