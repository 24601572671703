import React from "react";
import { StaticImage } from "gatsby-plugin-image";
let props = {
  objectPosition: "50% 50%",
  loading: "lazy",
  placeholder: "blurred",
  width: 80,
  height: 80,
};
export default [
  {
    description:
      "Upon learning that Ookla’s Speedtest app was being pirated in Chinese app stores, we engaged with the AppInChina team to identify a solution. Their app publishing expertise and extensive knowledge of the Chinese ecosystem allowed us to remove the hacked versions and ensure Chinese consumers were not being misled.",
    name: "Adam Alexander",
    position: "VP Strategic Partnerships at Ookla",
    img: <StaticImage {...props} src="../images/apps/ookla.jpg" alt="Ookla" />,
  },
  {
    description:
      "AppInChina helped us submit our apps to a number of top stores in China when we did not have a local team to do it. This helped us gain a strong foothold in the market and from there we were able to start building our presence in China.",
    name: "Gina Gotthilf",
    position: "Head of Marketing and International Development at Duolingo",
    img: (
      <StaticImage
        {...props}
        src="../images/apps/duolingo.webp"
        alt="Duolingo"
      />
    ),
  },
  {
    description:
      "Besides the app distribution service, we also benefited a lot from AppInChina’s market insights. Those insights helped us better plan out our marketing strategy and succeed in the market.",
    name: "Chenxi She",
    position: "China Country Manager at Busuu",
    img: <StaticImage {...props} src="../images/apps/busuu.jpeg" alt="Busuu" />,
  },

  {
    description:
      "AppInChina’s insightful research, constructive advice and strong relationships with the local stores made the entire process go smoothly, which helped us save time and development resources and provided us with a wider space to grow and increase our brand awareness.",
    name: "Jing Zhang",
    position: "Marketing Manager at The Economist Global Business Review",
    img: (
      <StaticImage
        {...props}
        src="../images/apps/the-economist.jpeg"
        alt="The Economist"
      />
    ),
  },
  {
    description:
      "AppInChina helped us to localize our complex cryptocurrency website and whitepapers quickly and with complete precision. I'd highly recommend them to anyone looking to localize their presence in China.",
    name: "Can Kisagun",
    position: "Co-Founder & CPO at Enigma",
    img: (
      <StaticImage {...props} src="../images/apps/enigma.png" alt="Enigma" />
    ),
  },
  {
    description:
      "AppInChina was the perfect partner for us, as they showed knowledge of the Chinese market and they were the only platform providing a fully-English language service, which ensured that the whole process went smoothly and allowed me to share information with the rest of our team.",
    name: "Vicky Yu",
    position: "Global Partnerships Manager at Eyeo",
    img: (
      <StaticImage
        {...props}
        src="../images/apps/adblockplus_browser.jpg"
        alt="Adblock Browser"
      />
    ),
  },
];
