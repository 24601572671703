import React from "react";
import * as classes from "./index.module.less";
import quotes from "src/utils/quotes";
import Carousel from "components/shared/Carousel";

export default () => (
  <>
    <div className={classes.container}>
      <h3>Trusted by the World's Best Companies</h3>
      <div className={classes.content}>
        <div className={classes.list}>
          {quotes
            .slice(0, 3)
            .map(({ description, name, position, img }, index) => (
              <div key={index} className={classes.item}>
                <p className={classes.description}>"{description}"</p>
                <div className={classes.flex}>
                  {img}
                  <div className={classes.subflex}>
                    <p className={classes.name}>{name}</p>
                    <p className={classes.position}>{position}</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
    <div className={classes.carousel}>
      <Carousel
        items={quotes}
        interval={6000}
        render={({ description, name, position, img }) => (
          <div className={classes.item}>
            <p className={classes.description}>"{description}"</p>
            <div className={classes.flex}>
              {img}
              <div className={classes.subflex}>
                <p className={classes.name}>{name}</p>
                <p className={classes.position}>{position}</p>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  </>
);
