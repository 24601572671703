import React, { useEffect, useState } from "react";
import * as classes from "./index.module.less";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

let getTriggerPoint = (id, offset) => {
  return document.getElementById(id).offsetTop - window.innerHeight + offset;
};

const imgProps = {
  objectPosition: "50% 50%",
  layout: "fullWidth",
  loading: "lazy",
  placeholder: "blurred",
};

export default () => {
  let [colored, setColored] = useState(false);

  useEffect(() => {
    let triggerPoint = getTriggerPoint("mediaIcons", window.innerHeight / 2);

    if (typeof window !== undefined) {
      window.addEventListener("scroll", (e) => {
        let scroll = window.scrollY;
        if (scroll > triggerPoint) {
          if (!colored) setColored(true);
        } else {
          if (!colored) setColored(false);
        }
      });
    }
  }, []);
  return (
    <div
      className={[classes.container, colored ? classes.colored : ""].join(" ")}
    >
      <h3>Featured in the Industry's Top Media Outlets</h3>
      <Link to="/media/">
        <div className={classes.logos} id="mediaIcons">
          <StaticImage
            src="../../../images/news_logos/wsj.png"
            alt="Wall Street Journal"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/telegraph.png"
            alt="The Telegraph"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/bloomberg.png"
            alt="Bloomberg"
            {...imgProps}
          />

          <StaticImage
            src="../../../images/news_logos/cnbc.png"
            alt="CNBC"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/businessinsider.png"
            alt="Business Insider"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/technode.png"
            alt="TechNode"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/abacus.png"
            alt="Abacus"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/pocketgamer.png"
            alt="Pocketgamer"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/arstechnica.png"
            alt="Arstechnica"
            {...imgProps}
          />

          <StaticImage
            src="../../../images/news_logos/statesman.png"
            alt="The Statesman"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/fortune.png"
            alt="Fortune"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/vogue.png"
            alt="Vogue Business"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/androidauthority.png"
            alt="Android Authority"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/techcrunch.png"
            alt="TechCrunch"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/yahoo.png"
            alt="Yahoo Finance"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/financialtimes.png"
            alt="Financial Times"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/appleinsider.png"
            alt="Apple Insider"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/9to5.png"
            alt="9to5Mac"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/st2.png"
            alt="The Strait Times"
            {...imgProps}
          />
          <StaticImage
            src="../../../images/news_logos/scmp.png"
            alt="South China Morning Post"
            {...imgProps}
          />
        </div>
      </Link>
    </div>
  );
};
