import React, { useEffect, useState, useRef } from "react";

export default ({ children, label }) => {
  let [displayed, setDisplayed] = useState(false);
  const el = useRef(null);

  useEffect(() => {
    setScrollListener();
  }, []);

  let setScrollListener = () => {
    if (typeof window == "undefined") return;
    window.addEventListener("scroll", () => {
      let scroll = window.scrollY;
      let trigger = el?.current?.offsetTop - window.innerHeight - 200;
      if (scroll > trigger && !displayed) {
        setDisplayed(true);
        console.log("displayed ",label)
      }
    });
  };
  return <div ref={el}>{displayed ? <>{children}</> : null}</div>;
};
