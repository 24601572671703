import React, { useEffect, useState } from "react";
import * as classes from "./index.module.less";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "components/shared/Carousel";

let getTriggerPoint = (id, offset) => {
  return document.getElementById(id).offsetTop - window.innerHeight + offset;
};

const imgProps = {
  objectPosition: "50% 50%",
  loading: "lazy",
  layout: "fullWidth",
  placeholder: "blurred",
};

export default () => {
  let [colored, setColored] = useState(false);

  useEffect(() => {
    let triggerPoint = getTriggerPoint("movingText", 0);
    let colorTriggerPoint = getTriggerPoint(
      "movingText",
      window.innerHeight / 2
    );

    let setStyle = (property, offset, it) => {
      if (
        offset > -100 &&
        offset < 200 &&
        it.style[property] !== `${offset.toFixed(0)}px`
      ) {
        it.style[property] = `${offset.toFixed(0)}px`;
      } else if (offset <= -100) {
        it.style[property] = `-100px`;
      } else if (offset >= 200) {
        it.style[property] = `200px`;
      }
    };

    if (typeof window !== undefined) {
      window.addEventListener("scroll", (e) => {
        let scroll = window.scrollY;

        if (scroll > colorTriggerPoint) {
          if (!colored) setColored(true);
        } else {
          if (!colored) setColored(false);
        }

        if (scroll > triggerPoint && window.innerWidth > 700) {
          let offset = (scroll - triggerPoint) / 9 - 30;
          document
            .querySelectorAll("#movingText > div:nth-child(odd)")
            .forEach((it) => {
              setStyle("left", offset, it);
            });
          document
            .querySelectorAll("#movingText > div:nth-child(even)")
            .forEach((it) => {
              setStyle("right", offset, it);
            });
        }
      });
    }
  }, []);

  let groups = [
    <>
      <StaticImage
        src="../../../images/company_logos/microsoft.png"
        alt="Uber"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/ferrero.png"
        alt="Adblock"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/merck.png"
        alt="Rakuten"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/uber_company.png"
        alt="Uber"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/adblock_company.png"
        alt="Adblock"
        {...imgProps}
      />
    </>,
    <>
      <StaticImage
        src="../../../images/company_logos/rakuten_company.png"
        alt="Rakuten"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/speedtest_company.png"
        alt="Speedtest"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/duke_company.png"
        alt="Duke"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/chubb_company.png"
        alt="Chubb"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/property_company.png"
        alt="PropertyGuru"
        {...imgProps}
      />
    </>,
    <>
      <StaticImage
        src="../../../images/company_logos/selfridges_company.png"
        alt="Selfridges"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/stadium_company.jpg"
        alt="Stadium Goods"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/groz_company.png"
        alt="myGrozbeckert"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/amer_company.png"
        alt="Amer Sports"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/april_company.png"
        alt="April"
        {...imgProps}
      />
    </>,
    <>
      <StaticImage
        src="../../../images/company_logos/logwin_company.png"
        alt="Logwin"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/onelogin_company.png"
        alt="OneLogin"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/sphero_company.png"
        alt="Sphero Play"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/withings_company.png"
        alt="Withings"
        {...imgProps}
      />
      <StaticImage
        src="../../../images/company_logos/light_company.png"
        alt="Lightricks"
        {...imgProps}
      />
    </>,
  ];

  return (
    <>
      <div
        className={[
          classes.logos,
          classes.gray,
          colored ? classes.colored : "",
        ].join(" ")}
        id="movingText"
      >
        {groups.map((it, index) => (
          <div key={index}>{it}</div>
        ))}
      </div>
      <div className={classes.carousel}>
        <Carousel
          interval={3000}
          items={groups}
          render={(it, index) => <div key={index}>{it}</div>}
        />
      </div>
    </>
  );
};
